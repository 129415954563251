"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _assetQueryKey = _interopRequireDefault(require("./assetQueryKey"));

var _usePortalAssets = require("./usePortalAssets");

var _usePortalTours = require("./usePortalTours");

var _usePortalCoupledAssets = require("./usePortalCoupledAssets");

var _usePortalHistoricalAssets = require("./usePortalHistoricalAssets");

var _usePortalArchivalAssets = require("./usePortalArchivalAssets");

var _usePortalHistoricalCoupledAssets = require("./usePortalHistoricalCoupledAssets");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  all: _assetQueryKey["default"],
  normal: _usePortalAssets.queryKeys,
  tour: _usePortalTours.queryKeys,
  coupled: _usePortalCoupledAssets.queryKeys,
  historical: _usePortalHistoricalAssets.queryKeys,
  archival: _usePortalArchivalAssets.queryKeys,
  historicalCoupled: _usePortalHistoricalCoupledAssets.queryKeys
};
exports["default"] = _default;