"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _nicbaseIcons = require("nicbase-icons");

var _default = {
  DRIVING: null,
  STANDING: null,
  REEFER_ON: _nicbaseIcons.CoolingActive,
  REEFER_OFF: _nicbaseIcons.CoolingInactive,
  DOOR_1_OPEN: _nicbaseIcons.DoorOpen,
  DOOR_1_CLOSED: _nicbaseIcons.DoorClosed,
  DOOR_1_NO_DATA: _nicbaseIcons.NoDoorData,
  DOOR_2_OPEN: _nicbaseIcons.DoorOpen,
  DOOR_2_CLOSED: _nicbaseIcons.DoorClosed,
  DOOR_2_NO_DATA: _nicbaseIcons.NoDoorData,
  DOOR_3_OPEN: _nicbaseIcons.DoorOpen,
  DOOR_3_CLOSED: _nicbaseIcons.DoorClosed,
  DOOR_3_NO_DATA: _nicbaseIcons.NoDoorData,
  COUPLED: _nicbaseIcons.CoupledOn,
  NOT_COUPLED: _nicbaseIcons.CoupledOff,
  LOADED: _nicbaseIcons.Loaded,
  NOT_LOADED: _nicbaseIcons.NotLoaded,
  FUEL_RESERVE: _nicbaseIcons.FuelReserve,
  NO_FUEL_RESERVE: _nicbaseIcons.NoFuelReserve,
  NO_GPS: _nicbaseIcons.NoGps,
  TK_ALERT: _nicbaseIcons.TKalarm,
  TK_CAUTION: _nicbaseIcons.TKcaution,
  TK_NOTIFICATION: _nicbaseIcons.TKnotification,
  CBT_ALERT: _nicbaseIcons.CargoBullAlarm,
  CBT_CAUTION: _nicbaseIcons.CargoBullCaution,
  CBT_NOTIFICATION: _nicbaseIcons.CargoBullNotification,
  CARRIER_ALERT: _nicbaseIcons.CarrierAlarm,
  CARRIER_CAUTION: _nicbaseIcons.CarrierCaution,
  CARRIER_NOTIFICATION: _nicbaseIcons.CarrierNotification,
  FLAT_SPOT: _nicbaseIcons.FlatSpot,
  GREEN_ALARM: _nicbaseIcons.WarningGreen,
  YELLOW_ALARM: _nicbaseIcons.WarningYellow,
  RED_ALARM: _nicbaseIcons.WarningRed,
  EVAPORATOR_1_DEFROST: _nicbaseIcons.DefrostingProcess,
  PANIC_BUTTON_ACTIVE: _nicbaseIcons.PanicButton,
  PANIC_BUTTON_INACTIVE: null,
  DOOR_LOCK_OPEN: _nicbaseIcons.FrigotransDoorContactRed,
  DOOR_LOCK_CLOSED: _nicbaseIcons.FrigotransDoorContactGreen,
  DOOR_LOCK_UNLOCKED: _nicbaseIcons.DoorUnlocked,
  DOOR_LOCK_LOCKED: _nicbaseIcons.DoorLocked,
  DOOR_LOCK_MODE_SECURE: _nicbaseIcons.SecureModeOn,
  DOOR_LOCK_MODE_NORMAL: _nicbaseIcons.SecureModeOff
};
exports["default"] = _default;